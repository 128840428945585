body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

.delete-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #0a4d37;
    padding: 20px;
}

.delete-account-box {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
}

.delete-account-heading {
    color: #0a4d37;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.checkbox-group {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
}

.checkbox-group input {
    margin-right: 10px;
    margin-top: 7px;
}

.checkbox-group label {
    font-size: 0.9rem;
    color: #555;
    padding-left: 10px;
}

.error-message {
    color: #d9534f;
    font-size: 0.9rem;
    margin-bottom: 15px;
    text-align: center;
}

.delete-button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #0a4d37;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #083b2a;
}
/* Reset default checkbox styles */
.checkbox-group input[type="checkbox"] {
    appearance: none; /* Hides the default checkbox */
    -webkit-appearance: none; /* For WebKit browsers */
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.checkbox-group input[type="checkbox"]:checked {
    /* background-color: #0a4d37;
    border-color: #0a4d37; */
}

/* Visible checkmark when checked */
/* .checkbox-group input[type="checkbox"]:checked::after {
    content: "";
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(45deg);
} */
/* Blue tick (✓) inside the checkbox */
.checkbox-group input[type="checkbox"]:checked::after {
    content: "";
    width: 6px;
    height: 12px;
    border: solid #007bff; /* Blue tick */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    left: 185%;
    top: 52%;
    transform: translate(-50%, -60%) rotate(45deg);
}