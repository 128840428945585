.refund-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #222;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  ol {
    margin: 0;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 15px;
  }
  
  ul {
    margin-top: 10px;
    padding-left: 20px;
    list-style-type: circle;
  }
  
  strong {
    color: #444;
  }
  
  @media (max-width: 768px) {
    .refund-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.75rem;
    }
  
    ol {
      padding-left: 15px;
    }
  
    ul {
      padding-left: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .refund-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    ol {
      padding-left: 10px;
    }
  
    ul {
      padding-left: 10px;
    }
  }
  