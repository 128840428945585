
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* 
Thin 100
ExtraLight 200
Light 300
Regular 400
Medium 500
SemiBold 600
Bold 700
ExtraBold 800
Black 900
Thin 100 Italic
ExtraLight 200 Italic
Light 300 Italic
Regular 400 Italic
Medium 500 Italic
SemiBold 600 Italic
Bold 700 Italic
ExtraBold 800 Italic
Black 900 Italic
 */
* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

:root {
    --primaryColor:#FFDC60;
    --secondaryColor:#FE4040;
    --tersiaryColor: #05054C;
    --baseTextColor:#6D768A;
    --baseFontSize:16px;
    --baseLineHeight:29px;
    --baseFontWeight:400;
    --primaryFont:"Poppins", sans-serif;
}

html,
body {
    height: 100%;
    font-optical-sizing: auto;
    font-variation-settings:"wdth" 100;
}

body {
    color: var(--baseTextColor);
    font-family:var(--primaryFont);
    font-size: var(--baseFontSize);
    font-weight: var(--baseFontWeight);
    line-height: var(--baseLineHeight);
}

a {
    transition: all .5s;
    text-decoration: none;
}
button {
    cursor: pointer;
}
img {
    max-width: 100%;
    height: auto;
}
.btn {
    padding: 12px 18px;
    font-size: var(--baseFontSize);
    font-weight: calc(var(--baseFontWeight) + 200);
    border-radius: 10px;
    border: 0;
    min-width: 140px;
}
.btn i {
    margin-right: 10px;
}
.btn-primary {
    background-color: var(--primaryColor);
    color: #132243;
}
.btn-primary:hover {
    background-color: var(--tersiaryColor);
    color: #fff;
}
.btn-primary:hover img {
    filter: brightness(0) invert(1);
}
.btn-secondary {
    background-color: var(--secondaryColor);
    color: #fff;
}
.btn-secondary:hover {
    background-color: var(--tersiaryColor);
}

.btn-outline {
    border: 1px solid #fff;
    color: #fff;
}
.btn-outline:hover {
    background-color: var(--tersiaryColor); 
}

.btn-link {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--secondaryFont);
    color: var(--secondaryColor);
    text-decoration: none;
}
.btn-link:hover {
    color: var(--primaryColor);
}

h1 {
    font-size: calc(var(--baseFontSize) + 30px);
    line-height: normal;
    font-weight: calc(var(--baseFontWeight) + 300);
}
h2 {
    font-size: calc(var(--baseFontSize) + 19px);
    line-height: normal;
    font-weight: calc(var(--baseFontWeight) + 300);
}
h3 {
    font-size: calc(var(--baseFontSize) + 10px);
    line-height: normal;
    font-weight: calc(var(--baseFontWeight) + 300);
}
h4 {
    font-size: calc(var(--baseFontSize) + 2px);
    line-height: normal;
    font-weight: calc(var(--baseFontWeight) + 200);
}
h5 {
    font-size: calc(var(--baseFontSize) - 2px);
    line-height: normal;
    font-weight: calc(var(--baseFontWeight) + 200);
}
.pt-70 {
    padding-top: 70px;
} 
.pb-70 {
    padding-bottom: 70px;
}
.pt-50 {
    padding-top: 50px;
} 
.pb-50 {
    padding-bottom: 50px;
}
.lightGray {
    background-color: #F4F4F4;
}
.darkGray {
    background-color: #F0F3F9;
}
.borR10 {
    border-radius: 10px;
}
/* Header */
.ldHeader {
    position: absolute;
    width: 100%;
    padding: 50px 0;
    top: 0;
    z-index: 5;
}
.ldHeader .navbar-expand-lg {
    padding: 0;
}
.ldHeader .navbar-expand-lg .navbar-brand {
    padding: 0;
}
.ldHeader .navbar-expand-lg .navbar-right {
    display: flex;
    align-items: center;
}
.ldHeader .navbar-expand-lg .navbar-nav .nav-link {
    font-size: var(--baseFontSize);
    font-weight: calc(var(--baseFontWeight) + 200);
    padding-left: 15px;
    padding-right: 15px;
    color: #fff;
}
.ldHeader .navbar-expand-lg .navbar-nav .nav-link:hover {
    color: var(--primaryColor);
}

.ldHeader .dropdown-toggle::after {
    border: 0;
    background: url(../images/menu-arrow.png) no-repeat 0 0;
    display: inline-flex;
    width: 11px;
    height: 7px;
}
.ldHeader .dropdown-menu {
    padding: 0;
    margin: 0;
    border: 0;
}
.ldHeader .dropdown-menu .dropdown-item {
    font-size: 12px;
    padding: 5px 10px;
    line-height: normal;
}
.ldHeader .dropdown-menu li:first-child .dropdown-item {
    border-radius: 5px 5px 0 0;
}
.ldHeader .dropdown-menu li:last-child .dropdown-item {
    border-radius: 0 0 5px 5px;
}
.ldHeader .dropdown-menu .dropdown-item:hover {
    background-color: var(--primaryColor);
    color: var(--tersiaryColor);
}
.ldHeader .dropdown-menu[data-bs-popper] {
    left: auto;
    right: 0;
}

.navbar-right .btn.btn-primary {
    order: 2;
}

/* Hero Banner */
.ldHeroBanner {
    background: url(../images/hero-bg.png) no-repeat center top;
    color: #fff;
    padding: 170px 0 0;
    height: 697px;
    position: relative;
}
.ldHeroBanner .hero-cnt {
    padding-top: 60px;
}
.ldHeroBanner .hero-cnt h1 {
    color: #fff;
}
.ldHeroBanner .hero-cnt h1 span {
    color: var(--primaryColor);
}
.ldHeroBanner .hero-cnt p {
    margin-bottom: 45px;
}
.ldHeroBanner .hero-img img {
    margin-left: 50px;
}

/* section two */
.sectionTwo {
    margin-top: -75px;
    padding-top: 180px;
    padding-bottom: 220px;
}
.sectionTwoCnt h2 {
    color: var(--tersiaryColor);
    margin-bottom: 25px;
}
.sectionTwoCnt p {
    line-height: 35px;
    margin-bottom: 35px;
}

/* Section Three */
.sectionThree {
    margin-top: -205px;
    color: #fff;
}
.sectionThree .bgIMG {
    background: url(../images/expert-bg.jpg) no-repeat right top;
    background-size: cover;
    border-radius: 20px;
    padding-left: 70px;
    padding-right: 70px;
}
.sectionThree h2 {
    margin-bottom: 25px;
}
.sectionThree h2 span {
    color: var(--primaryColor);
}
.sectionThree p {
    line-height: 35px;
    margin-bottom: 35px;
}

/* Section four */
.sectionFour {
    padding-top: 50px;
    padding-bottom: 100px;
}
.sectionFour h2 {
    color: var(--tersiaryColor);
}
.stepBox {
    align-items: center;
    padding: 15px 0;
    position: relative;
}
.stepBox:nth-child(odd) .stepBoxImg {
    order: 1;
    text-align: left;
}
.stepBox:nth-child(odd) .stepBoxCnt {
    order: 2;
}
.stepBox:nth-child(even) .stepBoxImg {
    order: 2;
    text-align: right;
}
.stepBox:nth-child(even) .stepBoxCnt {
    order: 1;
}
.stepBox .stepBoxCnt {
    padding-right: 120px;
}
.stepBox .stepBoxCnt span {
    color: #E94444;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    margin-bottom: 10px;
}
.stepBox .stepBoxCnt h3 {
    color: var(--tersiaryColor);
    margin-bottom: 25px;
}
.stepBox .stepBoxCnt p {
    line-height: 35px;
    margin-bottom: 0;
}
.stepBox:first-child:after {
    content: "";
    height: 47px;
    position: absolute;
    left: -110px;
    right: 0;
    bottom: -120px;
    margin: auto;
    display: block;
    background: url(../images/arrow-right.png) no-repeat 0 0;
    width: 401px;

}
.stepBox:nth-child(2):after {
    content: "";
    height: 65px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50px;
    margin: auto;
    display: block;
    background: url(../images/arrow-left.png) no-repeat 0 0;
    width: 360px;
}

/* Section Five */
.sectionFive h2 {
    color: var(--tersiaryColor);
    margin-bottom: 25px;
}
.sectionFive p {
    line-height: 35px;
    margin-bottom: 35px;
}

/* Section Six */
.sectionSix {
    padding-top: 100px;
}
.sectionSix h2 {
    color: var(--tersiaryColor);
    margin-bottom: 25px;
}
.sectionSix p {
    line-height: 35px;
}

/* Section Seven */
.sectionSeven.bgIMG {
    background: url(../images/getstart-bg.png) no-repeat center 0;
    /* background-size: cover; */
    height: 476px;
}
.sectionSeven {
    color: #fff;
    margin-top: 70px;
}
.sectionSeven h2 span {
    color: var(--primaryColor);
    margin-bottom: 25px;
}
.sectionSeven p {
    line-height: 35px;
    margin-bottom: 35px;
}
.sectionSevenImg {
    margin-top: -70px;
}

/* Section Eight */
.sectionEight {
    margin-bottom: 50px;
}
.sectionEight h2 {
    color: var(--tersiaryColor);
    margin-bottom: 40px;
}
.ratingStar {
    display: inline-block;
    margin-bottom: 15px;
}
.sectionEight h4 {
    color: #223140;
    margin-bottom: 20px;
}
.sectionEight h5 {
    margin-bottom: 0;
    color: #223140;
}
.sectionEight p {
    font-size: calc(var(--baseFontSize) - 2px);
    line-height: 28px;
    color: #223140;
}
.userBox {
    display: flex;
    align-items: center;
    padding-top: 30px;
    justify-content: center;
}
.userBox figure {
    margin-bottom: 0;
    margin-right: 15px;
}
.userBox figure img {
    border-radius: 100px;
    width: 49px;
    height: 49px;
}
.userBox figure+div {
    text-align: left;
}
.userBox figure+div h4 {
    margin-bottom: 0;
}
.js-testimonials {
    position: relative;
}
.js-testimonials .slick-dots {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    margin: auto;
}
.js-testimonials .slick-dots li {
    margin: 5px;
}
.js-testimonials .slick-dots li button {
    cursor: pointer;
    width: 7px;
    height: 7px;
    display: block;
    border-radius: 100px;
    border: 2px solid transparent;
    font-size: 0;
    background-color: #E2E2E2;
}
.js-testimonials .slick-dots li.slick-active button {
    background-color: var(--tersiaryColor);
    border-color: var(--tersiaryColor);
    width: 9px;
    height: 9px;
}
/* Section Nine */
.sectionNine h2 {
    color: var(--tersiaryColor);
    margin-bottom: 40px;
}
.accordion-item {
    border: 1px solid #DCDCDC !important;
    margin-bottom: 20px;
    background-color: transparent;
    border-radius: 20px !important;
    padding: 35px;
}
.accordion-header {
    margin-bottom: 0 !important;
    border-bottom: 0;
}
.accordion-button {
    font-size: 18px;
    font-weight: 600;
    color: #223140;
    background-color: transparent;
    padding: 0;
}
.accordion-button:focus {
    box-shadow: none;
    border: 0;
}
.accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}
.accordion-body {
    border: 0;
    padding: 25px 0 0;
}
.accordion-body p {
    margin-bottom: 0;
    color: #6D768A;
    line-height: 31px;
}
.accordion-button::after {
    width: 16px;
    height: 11px;
    background: url(../images/accordion-arrow-down.png) no-repeat 0 center;
    background-size: 100%;
}
.accordion-button:not(.collapsed)::after {
    width: 11px;
    height: 16px;
    background: url(../images/arrow-acordion.png) no-repeat 0 center;
    background-size: 100%;
    transform: none;
}

/* Footer */
.ldFooter {
    background: url(../images/footer-bg.jpg) no-repeat 0 0;
    background-size: cover;
    padding: 50px 0 0;
}
.footerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 65px;
}
.footerTop ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
}
.footerTop ul li {
    padding: 0 10px;
    color: #fff;
    font-weight: 600;
}
.footerTop ul li a:hover img {
    filter: brightness(0);
}
.footerBot {
    color: #fff;
    padding-bottom: 75px;
}
.footerBot h2 {
    color: var(--primaryColor);
}
.footerBot p {
    margin-bottom: 0;
}
.subsCription {
    position: relative;
}
.subsCription .form-control {
    height: 79px;
    border-radius: 15px;
    border: 1px solid #DCDCDC;
    background-color: transparent;
    font-size: 18px;
    color: #fff;
    padding-left: 30px;
    padding-right: 211px;
}
.subsCription .form-control:focus {
    border-color: #fff;
    box-shadow: none;
    outline: none;
    color: #fff;
}
.subsCription .form-control::placeholder {
    color: #E5E5E5;
    opacity: 1;
}
.subsCription .form-control::-ms-input-placeholder { 
    color: #E5E5E5;
}
.subsCription .btn.btn-primary {
    position: absolute;
    top: 0;
    right: -2px;
    padding: 27.5px 30px;
    border-radius: 20px;
}
.copyright {
    padding: 30px 0;
    border-top: 1px solid rgb(255, 255, 255, .3);
}
.copyright p {
    margin-bottom: 0;
    font-weight: 300;
    color: #fff;
    line-height: 23px;
}

/* Media CSS */
@media (max-width: 1199px){
    body {
        font-size: 15px;
    }
    h1 {
        font-size: calc(var(--baseFontSize) + 24px);
    }
    h2 {
        font-size: calc(var(--baseFontSize) + 16px);
    }
    .accordion-button {
        font-size: 17px;
    }
    .stepBox .stepBoxCnt {
        padding-right: 100px;
    }
}
@media (max-width: 991px){
    .ldHeroBanner .hero-img img {
        margin-left: 0;
    }
    h1 {
        font-size: calc(var(--baseFontSize) + 20px);
    }
    h2 {
        font-size: calc(var(--baseFontSize) + 14px);
    }
    h3 {
        font-size: calc(var(--baseFontSize) + 8px);
    }
    .ldHeroBanner .hero-cnt p {
        margin-bottom: 40px;
        line-height: normal;
    }
    .sectionTwo {
        margin-top: -150px;
    }
    .sectionTwo h2 {
        margin-bottom: 20px;
    }
    .sectionTwoCnt p {
        line-height: normal;
        margin-bottom: 30px;
    }
    .sectionThree .bgIMG {
        padding-left: 50px;
        padding-right: 50px;
    }
    .sectionThree h2 {
        margin-bottom: 20px;
    }
    .sectionThree p {
        line-height: normal;
        margin-bottom: 30px;
    }
    .sectionFour {
        padding-top: 0;
        padding-bottom: 55px;
    }
    .stepBox .stepBoxCnt {
        padding-right: 50px;
    }
    .stepBox .stepBoxCnt h3 {
        margin-bottom: 20px;
    }
    .stepBox .stepBoxCnt p {
        line-height: normal;
    }
    .stepBox:first-child:after,
    .stepBox:nth-child(2):after {
        bottom: -30px;
    }
    .sectionFive h2 {
        margin-bottom: 20px;
    }
    .sectionFive p {
        line-height: normal;
        margin-bottom: 30px;
    }
    .sectionSix {
        padding-top: 70px;
    }
    .sectionSix h2 {
        margin-bottom: 20px;
    }
    .sectionSix p {
        line-height: normal;
        margin-bottom: 0;
    }
    .sectionSeven {
        margin-top: 0;
    }
    .sectionSeven.bgIMG {
        height: auto;
        padding-bottom: 50px;
    }
    .sectionSevenImg {
        margin-top: 0;
    }
    .sectionSeven p {
        line-height: normal;
        margin-bottom: 30px;
    }
    .footerBot .col-md-6 {
        width: 33.33333333%;
    }
    .footerBot .col-md-6:nth-child(2){
        width: 66.66666667%;
    }
    .footerBot p {
        line-height: normal;
    }
    .accordion-item {
        padding: 30px;
    }
    .accordion-body {
        padding-top: 20px;
    }
    .navbar-right .navbar-toggler {
        margin-left: 15px;
        background-color: var(--tersiaryColor);
        padding: 7px 10px;
        border: 0;
    } 
    .navbar-right .navbar-collapse {
        position: fixed;
        background-color: var(--tersiaryColor);
        right: -300px;
        top: 0;
        padding: 10px;
        width: 300px;
        height: 100vh;
        padding-top: 60px;
        z-index: 4;
        transition: all .1s;
        display: block;
    }
    .navbar-collapse.show {
        right: 0;
    }
    .ldHeader .dropdown-menu {
        background-color: transparent;
        padding: 0 10px;
    }
    .ldHeader .dropdown-menu .dropdown-item {
        font-size: 14px;
        color: #fff;
    }
    .navbar-toggler-icon {
        filter: brightness(0) invert(1);
    }
    body.noScroll {
        overflow: hidden;
    }
    body.noScroll:after {
        content: "";
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: rgb(0, 0, 0, .5);
        z-index: 3;
    }
    .navbar-toggler.menuOn {
        position: fixed;
        z-index: 5;
        top: 10px;
        right: 15px;
    }
    .navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }
    .navbar-right .btn.btn-primary {
        order: unset;
    }
}
@media (max-width: 767px){
    .ldHeader {
        padding: 25px 0;
    }
    .ldHeroBanner {
        padding-top: 120px;
        padding-bottom: 50px;
        background-color: #F4F4F4;
        height: auto;
    }
    .ldHeroBanner .hero-cnt {
        padding-top: 0;
    }
    .sectionTwo {
        margin-top: 0;
        padding: 50px 0;
    }
    .sectionTwoImg {
        margin-bottom: 30px;
        text-align: center;
    }
    .sectionThree {
        margin-top: 0;
        padding: 50px 0;
    }
    .sectionThree .bgIMG {
        padding: 50px;
    }
    .sectionFour {
        padding-bottom: 35px;
    }
    .stepBox .stepBoxImg {
        text-align: center !important;
        order: 1 !important;
        margin-bottom: 15px;
    }
    .stepBox .stepBoxCnt {
        order: 2 !important;
        padding-right: 12px;
    }
    .stepBox:first-child:after,
    .stepBox:nth-child(2):after {
        display: none;
    }
    .sectionFive {
        padding: 50px 0;
    }
    .sectionSix {
        padding: 50px 0;
    }
    .locationMap {
        padding-top: 50px;
    }
    .sectionSeven.bgIMG {
        background-size: cover;
    }
    .sectionSevenImg {
        text-align: center;
        margin-bottom: 30px;
    }
    .sectionEight {
        padding: 50px 0;
        margin-bottom: 30px;
    }
    .sectionEight h2 {
        margin-bottom: 30px;
    }
    .js-testimonials {
        padding: 30px;
    }
    .sectionEight p {
        line-height: normal;
    }
    .userBox {
        padding-top: 15px;
    }
    .js-testimonials .slick-dots {
        bottom: -30px;
    }
    .sectionNine {
        padding: 50px 0;
    }
    .sectionNine h2 {
        margin-bottom: 30px;
    }
    .sectionNineImg {
        text-align: center !important;
        order: 1;
        margin-bottom: 30px;
    }
    .sectionNineCnt {
        order: 2;
    }
    .accordion-item:last-child {
        margin-bottom: 0;
    }
    .footerTop {
        padding-bottom: 30px;
    }
    .footerBot {
        padding-bottom: 50px;
    }
    .footerBot .col-md-6 {
        width: 100%;
    }
    .footerBot .col-md-6:nth-child(2){
        width: 100%;
    }
    .footerBotLft {
        margin-bottom: 30px;
    }
    .copyright {
        padding: 20px 0;
    }
}
@media (max-width:575px){
    .ldHeroBanner {
        padding-bottom: 25px;
        background-size: cover;
    }
    .hero-img {
        margin-bottom: 30px;
    }
    .ldHeroBanner .hero-cnt {
        text-align: center;
        order: 2;
    }
    .ldHeroBanner .hero-cnt p {
        margin-bottom: 30px;
    }
    .ldHeroBanner .hero-cnt h1 {
        line-height: 45px;
    }
    .sectionTwo {
        padding: 25px 0;
    }
    .sectionTwoCnt {
        text-align: center;
    }
    .sectionThree {
        padding: 25px 0;
    }
    .sectionThree .bgIMG {
        padding: 20px;
    }
    .sectionThree h2 {
        line-height: 34px;
    }
    .sectionFour {
        padding-bottom: 10px;
    }
    .stepBox .stepBoxCnt {
        text-align: center;
    }
    .sectionFive {
        padding: 25px 0;
    }
    .sectionFive h2 {
        line-height: 34px; 
    }
    .sectionSix {
        padding: 25px 0;
    }
    .sectionSix h2 {
        line-height: 34px;
    }
    .locationMap {
        padding-top: 25px;
    }
    .sectionSeven.bgIMG {
        padding: 25px 0;
    }
    .sectionSevenCnt {
        text-align: center;
    }
    .sectionEight {
        padding: 25px 0;
    }
    .js-testimonials {
        padding: 20px;
    }
    .sectionNine {
        padding: 25px 0;
    }
    .sectionNine h2 {
        line-height: 34px;
        text-align: center;
    }
    .accordion-item {
        padding: 20px;
        margin-bottom: 10px;
    }
    .accordion-button {
        line-height: 26px;
    }
    .accordion-body p {
        line-height: normal;
    }
    .ldFooter {
        padding: 25px 0 0;
    }
    .footerTop {
        padding-bottom: 25px;
        display: block;
        text-align: center;
    }
    .footerTop ul {
        justify-content: center;
        margin-top: 20px;
    }
    .footerBot {
        padding-bottom: 25px;
    }
    .footerBot h2 {
        line-height: 34px;
    }
    .footerBotLft {
        margin-bottom: 20px;
    }
    .subsCription .form-control {
        height: 55px;
        padding-left: 15px;
        padding-right: 165px;
    }
    .subsCription .btn.btn-primary {
        padding: 15px;
    }
    .copyright {
        padding: 15px 0;
    }
    .ldHeader .navbar-expand-lg .navbar-brand {
        width: 120px;
    }
}
@media (max-width: 380px){
    .ldHeader .navbar-expand-lg .navbar-brand {
        width: 100px;
    }
}
@media (max-width: 359px){
    .navbar-right .navbar-toggler {
        margin-left: 10px;
    }
    .navbar-right .btn {
        padding: 10px 12px;
        font-size: 14px;
        min-width: 120px;
    }
    .navbar-right .navbar-toggler {
        padding: 5px 8px;
    }
}
.footerLinks {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap; /* Ensures links wrap on smaller screens */
}

.footerLinks li a {
    color: #fff; /* Replace with your desired color */
    text-decoration: none;
    font-size: 1rem; /* Base font size */
}

.footerLinks li a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 576px) {
    .footerLinks {
        gap: 10px; /* Reduce gap for smaller screens */
    }

    .footerLinks li a {
        font-size: 0.9rem; /* Slightly smaller text size for small screens */
    }
}

