/* Page Container */
.delete-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #00473e;
    /* Dark Green Background */
    margin: 0;
}

/* Content Box */
.delete-content-box {
    background: #ffffff;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Heading */
.delete-heading {
    color: #00473e;
    /* Dark Green */
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

/* Description */
.delete-description {
    color: #555555;
    font-size: 16px;
    margin-bottom: 15px;
}

/* Info List */
.delete-info-list {
    list-style-type: disc;
    color: #333333;
    text-align: left;
    margin: 0 auto 20px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 1.5;
}

/* Confirm Button */
.delete-confirm-button {
    background-color: #00473e;
    /* Dark Green */
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.delete-confirm-button:hover {
    background-color: #00362e;
    /* Darker Green */
}

ul {
    margin-left: 20px;
    list-style-type: disc;
    color: #555555;
}

ul li {
    font-size: 16px;
    color: #555555;
    margin-bottom: 8px;
}