.privacy-container {
    max-width: 2000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    /* Light background for the container */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    /* Dark text color for the title */
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 3px solid #FFD700;
    /* Gold bottom border */
    font-family: 'Arial', sans-serif;
    /* Clean sans-serif font */
}

section {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #333;
    /* Dark background for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 2000px;
    margin: 20px auto;
    color: white;
    /* White text color */
}

h2 {
    font-size: 24px;
    font-weight: bold;
    color: white;
    /* White font color for the heading */
    margin-bottom: 16px;
    text-align: center;
}

p {
    font-size: 16px;
    color: white;
    /* White font color for paragraphs */
    margin-bottom: 16px;
    text-align: justify;
}

ul {
    margin-left: 20px;
    list-style-type: disc;
}

ul li {
    font-size: 16px;
    color: white;
    /* White font color for list items */
    margin-bottom: 8px;

    strong {
        font-weight: bold;
        color: white;
        /* Gold color for emphasis */
    }
}

strong {
    font-weight: bold;
    color: white;
    /* Gold color for emphasis */
}

ul li ul {
    list-style-type: circle;
}

ul li ul li {
    margin-left: 20px;
    font-size: 15px;
    color: white;
    /* White font color for nested list items */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    h1 {
        font-size: 28px;
        /* Adjust title size for tablets */
    }

    .privacy-container {
        padding: 15px;
        margin: 10px;
    }

    section {
        padding: 15px;
        margin: 10px;
    }

    h2 {
        font-size: 20px;
        /* Slightly smaller heading */
    }

    p {
        font-size: 14px;
        /* Smaller text on smaller screens */
    }

    ul li {
        font-size: 14px;
        /* Adjust list item size */
    }

    ul li ul li {
        font-size: 13px;
        /* Adjust nested list size */
    }
}

@media screen and (max-width: 480px) {
    h1 {
        font-size: 24px;
        /* Adjust title size for mobile */
    }

    .privacy-container {
        padding: 10px;
        margin: 5px;
    }

    section {
        padding: 10px;
        margin: 5px;
    }

    h2 {
        font-size: 18px;
        /* Even smaller heading for very small screens */
    }

    p {
        font-size: 12px;
        /* Smaller text for very small screens */
    }

    ul li {
        font-size: 12px;
        /* Smaller list item text */
    }

    ul li ul li {
        font-size: 11px;
        /* Smaller nested list item text */
    }
}