.confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color:  #00473e;
  }
  
  .confirmation-box {
    text-align: center;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
  }
  
  .checkmark {
    font-size: 32px;
    color: #2d8a35;
    background: #e6f7e6;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
  }
  
  .footer-text {
    font-size: 12px;
    color: #888;
  }
  