.terms-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #222;
    margin-bottom: 20px;
  }
  
  ol {
    margin: 20px 0;
    padding-left: 40px;
  }
  
  ol li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .terms-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .terms-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.25rem;
    }
  
    ol li {
      font-size: 0.9rem;
    }
  }
  