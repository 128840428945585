body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  /* Container styles */
  .contact-us-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 50px auto;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Left section (Contact Info) */
  .contact-us-left {
    flex: 1;
    background-color: #46276c;
    color: #fff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
  
  .contact-us-left h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .contact-us-left p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .contact-us-left a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-us-left a:hover {
    text-decoration: underline;
  }
  
  /* Right section (Form) */
  .contact-us-right {
    flex: 2;
    padding: 40px;
  }
  
  .contact-us-right h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Form styles */
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 15px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  textarea {
    height: 120px;
    resize: none;
  }
  
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #46276c;
    box-shadow: 0 0 4px rgba(70, 39, 108, 0.5);
  }
  
  /* Button styles */
  button {
    padding: 15px;
    font-size: 16px;
    background-color: #46276c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #331e4d;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .contact-us-container {
      flex-direction: column;
    }
  
    .contact-us-left,
    .contact-us-right {
      flex: unset;
      width: 100%;
    }
  
    .form-row {
      flex-direction: column;
    }
  }
  